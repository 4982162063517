import React, { useState } from "react";

/**
 * message = { role: "user"|"assistant", content: string }
 */
const ChatMessage = ({ message }) => {
  const isUser = message.role === "user";

  return (
    <div
      className="rag-chat-message-wrapper"
      style={{
        display: "flex",
        justifyContent: isUser ? "flex-end" : "flex-start",
      }}
    >
      <div
        className="rag-chat-message-bubble"
        style={{
          maxWidth: "85%",
          padding: "1rem",
          borderRadius: "0.75rem",
          boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          backgroundColor: isUser ? "#2563eb" : "#ffffff",
          color: isUser ? "#ffffff" : "#1f2937",
          border: isUser ? "none" : "1px solid #e5e7eb",
        }}
      >
        <p
          className="rag-chat-message-text"
          style={{
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            margin: 0,
          }}
        >
          {message.content}
        </p>
      </div>
    </div>
  );
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = {
      role: "user",
      content: input,
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setLoading(true);

    try {
      const response = await fetch(
        "https://rag-chatbot-2024-928663d7ff49.herokuapp.com/api/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({ query: userMessage.content }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const assistantMessage = {
        role: "assistant",
        content: data.response,
      };

      setMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "Sorry, there was an error processing your request.",
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="rag-chat-container"
      style={{
        maxWidth: "768px",
        width: "100%",
        margin: "0 auto",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div
        className="rag-chat-wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "600px",
          border: "1px solid #e5e7eb",
          borderRadius: "0.75rem",
          boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          backgroundColor: "#ffffff",
          overflow: "hidden",
        }}
      >
        <div
          className="rag-chat-header"
          style={{
            padding: "1rem",
            borderBottom: "1px solid #e5e7eb",
            backgroundColor: "#ffffff",
          }}
        >
          <h2
            className="rag-chat-title"
            style={{
              fontSize: "1.125rem",
              fontWeight: 600,
              color: "#1f2937",
              margin: 0,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            Chat with Your PDF
          </h2>
        </div>

        <div
          className="rag-chat-messages"
          style={{
            flex: 1,
            overflowY: "auto",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            backgroundColor: "#f9fafb",
          }}
        >
          {messages.map((message, index) => (
            <ChatMessage key={index} message={message} />
          ))}
          {loading && (
            <div
              className="rag-chat-loading"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <div className="rag-dot-flashing" />
            </div>
          )}
        </div>

        <form
          onSubmit={handleSubmit}
          className="rag-chat-input-form"
          style={{
            padding: "1rem",
            borderTop: "1px solid #e5e7eb",
            backgroundColor: "#ffffff",
          }}
        >
          <div
            className="rag-chat-input-group"
            style={{
              display: "flex",
              gap: "0.75rem",
            }}
          >
            <input
              className="rag-chat-input"
              type="text"
              disabled={loading}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ask a question about your document..."
              style={{
                flex: 1,
                padding: "0.75rem 1rem",
                border: "1px solid #d1d5db",
                borderRadius: "0.5rem",
                backgroundColor: loading ? "#f3f4f6" : "#ffffff",
                color: "#111827",
                fontSize: "1rem",
                outline: "none",
              }}
            />
            <button
              className="rag-chat-submit"
              type="submit"
              disabled={loading}
              style={{
                padding: "0.75rem 1.5rem",
                backgroundColor: loading ? "#93c5fd" : "#2563eb",
                color: "#ffffff",
                borderRadius: "0.5rem",
                cursor: loading ? "not-allowed" : "pointer",
                fontWeight: 500,
                border: "none",
                transition: "background-color 0.2s ease-in-out",
              }}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const FileUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://rag-chatbot-2024-928663d7ff49.herokuapp.com/api/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      setMessage("Error uploading file");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div
      className="rag-file-upload-container"
      style={{
        maxWidth: "768px",
        width: "100%",
        margin: "0 auto",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div
        className="rag-file-upload-box"
        style={{
          marginBottom: "2rem",
          padding: "1.5rem",
          border: "1px solid #e5e7eb",
          borderRadius: "0.75rem",
          boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
          backgroundColor: "#ffffff",
        }}
      >
        <h2
          className="rag-file-upload-title"
          style={{
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "1rem",
            color: "#1f2937",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Upload PDF Document
        </h2>
        <div
          className="rag-file-input-container"
          style={{
            position: "relative",
          }}
        >
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileUpload}
            className="rag-file-input"
            style={{
              display: "block",
              width: "100%",
              fontSize: "1rem",
              color: "#111827",
              cursor: "pointer",
            }}
          />
        </div>
        {uploading && (
          <p
            className="rag-file-uploading"
            style={{
              marginTop: "0.75rem",
              fontSize: "0.875rem",
              color: "#2563eb",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              className="rag-upload-spinner"
              style={{
                animation: "rag-spin 1s linear infinite",
                height: "1rem",
                width: "1rem",
                marginRight: "0.5rem",
              }}
              viewBox="0 0 24 24"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
                fill="none"
                style={{ opacity: 0.25 }}
              />
              <path
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                style={{ opacity: 0.75 }}
              />
            </svg>
            Uploading...
          </p>
        )}
        {message && (
          <p
            className="rag-file-message"
            style={{
              marginTop: "0.75rem",
              fontSize: "0.875rem",
              color: "#059669",
              fontWeight: 500,
            }}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

const RagChatBot = () => {
  return (
    <>
      <style>
        {`
          @keyframes rag-spin {
            to {
              transform: rotate(360deg);
            }
          }
          @keyframes rag-dotFlashing {
            0% {
              background-color: #000;
            }
            50%, 100% {
              background-color: #ccc;
            }
          }
          .rag-dot-flashing {
            position: relative;
            width: 1rem;
            height: 1rem;
            border-radius: 0.5rem;
            margin: 0 auto;
            background-color: #000;
            color: #000;
            animation: rag-dotFlashing 1s infinite linear alternate;
          }
        `}
      </style>

      <div
        className="rag-main-container"
        style={{
          minHeight: "100vh",
          backgroundColor: "#fafafa",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          rowGap: "1rem",
        }}
      >
        <FileUpload />
        <Chat />
      </div>
    </>
  );
};

export default RagChatBot;
