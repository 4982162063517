import React, { useRef } from "react";
import "./content.css";
import Home from "./home.png";
import Doctor from "./doctor.png";
import Services from "./services.png";
import AISolution from "./ai-solution.png";
import { Link as RouterLink } from "react-router-dom";
import ScreenReader from "../../Accessibility/SreenReader";

const Content = () => {
  const mainContentRef = useRef();

  const EHOSPITAL_NAME = "eHospital";
  const EHOSPITAL_DESCRIPTION = "Smart Digital Medicine Solutions";

  const CommonBlock = ({ children, backgroundColor, id }) => (
    <div className="landing-common-block" style={{ backgroundColor }} id={id}>
      {children}
    </div>
  );

  const FeatureBlock = ({ title, description }) => (
    <div className="feature-block">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );

  const ServiceBlock = ({ title, description, link }) => (
    <div className="service-block">
      <h3>{title}</h3>
      <p>{description}</p>
      <RouterLink to={link}>
        <button className="btn">More Info</button>
      </RouterLink>
    </div>
  );

  const AISolutionBlock = ({ description }) => (
    <div className="ai-solution-block">
      <p>{description}</p>
    </div>
  );

  const ContactBlock = ({ title }) => (
    <div className="contact-block">
      <h3>{title}</h3>
    </div>
  );

  return (
    <div className="landing-page-content" ref={mainContentRef}>
      <CommonBlock backgroundColor="#f2f7ff" id="content-home">
        <div className="home-content">
          <div className="landing-left">
            <h1>{EHOSPITAL_NAME}</h1>
            <h4>{EHOSPITAL_DESCRIPTION}</h4>
            <RouterLink to="/JoinUs">
              <button className="btn">Join Us Now</button>
            </RouterLink>
          </div>
          <div className="landing-right">
            <img src={Home} alt="Home" />
          </div>
        </div>
        <div className="feature-blocks">
          <FeatureBlock
            title="Platform"
            description="Provide a full-featured online communication platform for doctors and patients."
          />
          <FeatureBlock
            title="Machine Learning"
            description="Utilizing the latest machine learning algorithms to assist in diagnosing diseases with high accuracy."
          />
          <FeatureBlock
            title="User-oriented"
            description="Customized personalization for users, offering user-friendly functions and interfaces."
          />
        </div>
      </CommonBlock>
      <CommonBlock backgroundColor="#ffffff" id="about">
        <div className="about-content">
          <div className="landing-left">
            <img src={Doctor} alt="Doctor" />
          </div>
          <div className="landing-right">
            <h3>About us</h3>
            <h2>Electrical platform for every role</h2>
            <p>
              Simplify patient care through a digital platform with virtual
              consultations, electronic record management, and secure
              communication, enhancing medical service efficiency and
              convenience, supporting personalized healthcare at home.
            </p>
          </div>
        </div>
      </CommonBlock>
      <CommonBlock backgroundColor="#f2f7ff">
        <div className="mission-content">
          <h3>Our Mission</h3>
          <div className="mission-text">
            <p>
              <b>Empower Patients: </b>
            </p>
            <p>
              We believe that healthcare should be patient-centric. Our eHealth
              system puts individuals in the driver's seat of their health
              journey, enabling them to make informed decisions, access their
              health records, and interact with healthcare professionals
              seamlessly.
            </p>
            <p>
              <b>Support Health Care Providers: </b>
            </p>
            <p>
              We understand the challenges healthcare professionals face daily.
              Our eHealth platform is designed to streamline their work, enhance
              communication, and provide valuable insights that aid in
              delivering superior patient care.
            </p>
            <p>
              <b>Ensure Data Security: </b>
            </p>
            <p>
              Your health data is precious. We prioritize the highest standards
              of data security and privacy to ensure that your sensitive
              information remains confidential and secure.
            </p>
          </div>
        </div>
      </CommonBlock>
      <CommonBlock backgroundColor="#ffffff" id="services">
        <div className="services-content">
          <div className="landing-left">
            <h3>Services</h3>
            <h2>Electrical platform for every role</h2>
          </div>
          <div className="landing-right">
            <img src={Services} alt="Services" />
          </div>
        </div>
        <div className="service-blocks">
          <ServiceBlock
            title="For Doctors"
            description="Streamline patient care with virtual consultations, record management, and secure communication"
            link="/services"
          />
          <ServiceBlock
            title="For Patients"
            description="Access medical records, schedule virtual appointments, and communicate with healthcare providers."
            link="/services"
          />
          <ServiceBlock
            title="For Clinics"
            description="Collaborate with doctors to schedule work, communicate with medical users, and create forms online."
            link="/services"
          />
        </div>
      </CommonBlock>
      <CommonBlock backgroundColor="#f2f7ff" id="ai-solution">
        <div className="ai-solution-content">
          <div className="landing-left">
            <img src={AISolution} alt="AI Solution" />
          </div>
          <div className="landing-right">
            <h3>AI Solution</h3>
            <h2>Experienced in multiple medical practices</h2>
            <p>Artificial intelligence technology combined with diagnostics.</p>
            <RouterLink to="/Specialities">
              <button className="btn">Specialities</button>
            </RouterLink>
          </div>
        </div>
        <div className="ai-solution-blocks">
          <AISolutionBlock description="We utilize cutting-edge technology, including Machine Learning (ML) models, to offer advanced healthcare services, focusing on precise and swift diagnostics for early ailment detection." />
          <AISolutionBlock description="We enable seamless integration with diagnostic labs for efficient test ordering, results retrieval, and collaborative patient diagnostics." />
          <AISolutionBlock description="Our platform facilitates AI-driven analysis, enhancing the accuracy and speed of medical assessments." />
        </div>
      </CommonBlock>
      <CommonBlock backgroundColor="#ffffff" id="contact">
        <div className="contact-content">
          <h3>A Widely Used Platform</h3>
        </div>
        <div className="contact-blocks">
          <ContactBlock title="3+ Countries" />
          <ContactBlock title="150+ Doctors" />
          <ContactBlock title="300+ Patients" />
        </div>
      </CommonBlock>
      <CommonBlock backgroundColor="#f2f7ff">
        <div className="questions">
          <div className="info">
            <p>
              Our application process ensures the privacy of each client's
              information.
            </p>
            <p>
              At the same time, each application will be checked by
              professionals to ensure efficient.
            </p>
          </div>
          <div className="contact-buttons">
            <RouterLink to="/JoinUs">
              <button className="btn">Join Us Now</button>
            </RouterLink>
            <RouterLink to="/Contact">
              <button className="btn">Have Questions</button>
            </RouterLink>
          </div>
        </div>
      </CommonBlock>
      <ScreenReader contentRef={mainContentRef} />
    </div>
  );
};

export default Content;
